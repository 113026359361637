import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './bullet-item.scss';

export function BulletItem({ title, icon, iconClassName, titleClassName, accessibilityText, withAnimation }) {
  return (
    <li className="d-flex align-items-center mb-1">
      <i aria-hidden className={classnames('size-20 mr-0_5', icon, iconClassName)} />
      {withAnimation && <span className={classnames('animated-bullet-text', titleClassName)} data-text={title} />}
      {!withAnimation && <span className={titleClassName}>{title}</span>}
      {accessibilityText && <span className="sr-only">{accessibilityText}</span>}
    </li>
  );
}

BulletItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  accessibilityText: PropTypes.string,
  withAnimation: PropTypes.bool,
};
BulletItem.defaultProps = {
  iconClassName: null,
  titleClassName: null,
  accessibilityText: null,
  withAnimation: false,
};
