import {
  BINARY,
  INPUT,
  RADIO_LIST,
  ZIP,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-types';
import {
  START,
  ELIGIBILITY,
  RESULT,
  CHECKING_ELIGIBILITY,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/screen-types';
import {
  QUESTION,
  SCREEN,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/question-variants';
import { PROGRAM_TYPES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/program-types';
import { CATEGORIES } from 'site-modules/shared/components/incentives/incentives-wizard/constants/categories';
import { getCurrentYear } from 'site-modules/shared/utils/time-util';
import { getUtilityEvChargingOfferRebates } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-utility-ev-charging-offer-rebates';
import { capitalize, constant, get } from 'lodash';
import { TrackingConstant } from 'client/tracking/constant';
import {
  DAC_QUESTION_HINT,
  LOCAL_GROSS_INCOME_HINT,
  SCREEN_HINT,
} from 'site-modules/shared/components/incentives/incentives-wizard/constants/screen-hint';
import { getTaxFilingStatuses } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-tax-filing-statuses';
import { QualifierDescription } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/components/qualifier-description';
import { StartInputAdornment } from 'site-modules/shared/components/incentives/incentives-wizard/steps/questions/components/start-input-adornment';
import { PUB_STATES_LOWERCASE } from 'client/constants/pub-states';

export const START_SCREEN_ID = '66d40d44-8f71-11ee-b9d1-0242ac120002';

export const CHECK_FEDERAL_REBATES_SCREEN_ID = '8990d7d6-8f71-11ee-b9d1-0242ac120002';
export const RESULT_FEDERAL_REBATES_SCREEN_ID = '8990da4c-8f71-11ee-b9d1-0242ac120002';
export const CHECK_LOCAL_REBATES_SCREEN_ID = '8990db78-8f71-11ee-b9d1-0242ac120002';
export const RESULT_LOCAL_REBATES_SCREEN_ID = '8990dc9a-8f71-11ee-b9d1-0242ac120002';
export const CHECKING_FEDERAL_ELIGIBILITY_RESULT_SCREEN_ID = 'bfc85bd7-3c9e-4b56-b20d-a6bbd73efddb';
export const CHECKING_LOCAL_ELIGIBILITY_RESULT_SCREEN_ID = 'b72380d8-7417-4cd4-b78c-a467c3867f0b';
export const RESULTS_SCREEN_ID = '8990deb6-8f71-11ee-b9d1-0242ac120002';

export const TRANSACTION_TYPE_QUESTION_ID = 'a9553968-8f71-11ee-b9d1-0242ac120002';
export const LOWER_INCOME_YEAR_QUESTION_ID = 'a9553cce-8f71-11ee-b9d1-0242ac120002';
export const ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID = 'a95s2551-8f71-11ee-b9d1-0242ac120002';
export const ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID = 'a95s2551-7f71-11ee-b9d1-0242ac120003';
export const TAX_FILING_STATUS_FEDERAL_QUESTION_ID = 'a94s2551-8f71-11ee-b9d1-0242ac120002';
export const TAX_FILING_STATUS_LOCAL_QUESTION_ID = '16827c61-2f93-4ebc-add5-875e07b54d67';
export const INSTALL_EV_CHARGER_QUESTION_ID = 'a9554458-8f71-11ee-b9d1-0242ac120002';
export const ZIP_CODE_QUESTION_ID = 'a95545ac-8f71-11ee-b9d1-0242ac120002';
export const CUSTOMER_OF_QUESTION_ID = 'a95546d8-8f71-11ee-b9d1-0242ac120002';
export const HOUSEHOLD_SIZE_QUESTION_ID = 'fcf7c0df-c0e3-4681-a5e4-1c2696464b64';
export const PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID = 'fc28959d-a5e0-4890-96a6-00af1aec9a3d';
export const RESIDE_DISADVANTAGE_COMMUNITY_QUESTION_ID = '369eecbb-943d-406d-9437-dab322c6d677';
export const ENGINE_TYPE_QUESTION_ID = 'de43cd9b-49b4-4bf2-a2d7-606cc7b1bd3c';

export const DEFAULT_CUSTOMER_OF_ANSWER = '00000000-0000-0000-0000-000000000001';

export const questions = {
  [START_SCREEN_ID]: {
    id: START_SCREEN_ID,
    variant: SCREEN,
    type: START,
    title: ({ year, make, model, type }) => {
      const ymm = `${(type !== PUB_STATES_LOWERCASE.USED && year) || ''} ${make} ${model}`.trim();
      return `Maximize savings on your ${ymm}`;
    },
    hideHeadSection: true,
  },
  [CHECK_FEDERAL_REBATES_SCREEN_ID]: {
    id: CHECK_FEDERAL_REBATES_SCREEN_ID,
    variant: SCREEN,
    type: ELIGIBILITY,
    title: ({ type }) => {
      const typePart = type?.toLowerCase() === PUB_STATES_LOWERCASE.USED ? ' used ' : ' ';

      return `Let’s check your eligibility for federal${typePart}EV credit.`;
    },
    autoAnswer: [CATEGORIES.FEDERAL],
    categories: [CATEGORIES.FEDERAL],
    trackingSubaction: constant(TrackingConstant.DISPLAY_FEDERAL_TAX_CREDIT_INTRO),
  },
  [CHECKING_FEDERAL_ELIGIBILITY_RESULT_SCREEN_ID]: {
    id: CHECKING_FEDERAL_ELIGIBILITY_RESULT_SCREEN_ID,
    variant: SCREEN,
    type: CHECKING_ELIGIBILITY,
    title: () => '',
    categories: [CATEGORIES.FEDERAL],
    autoCloseAfter: 1,
  },
  [RESULT_FEDERAL_REBATES_SCREEN_ID]: {
    id: RESULT_FEDERAL_REBATES_SCREEN_ID,
    variant: SCREEN,
    type: ELIGIBILITY,
    title: (context, stepMeta, isEligible) => {
      const typePart = context.type?.toLowerCase() === PUB_STATES_LOWERCASE.USED ? ' used ' : ' ';

      return isEligible
        ? `Nice! You’re eligible for the Federal${typePart}EV Credit.`
        : `Unfortunately, you do not qualify for the Federal${typePart}EV Credit.`;
    },
    categories: [CATEGORIES.FEDERAL],
    hideBullets: true,
    trackingSubaction: constant(TrackingConstant.DISPLAY_FEDERAL_TAX_CREDIT_RESULTS),
  },
  [CHECK_LOCAL_REBATES_SCREEN_ID]: {
    id: CHECK_LOCAL_REBATES_SCREEN_ID,
    variant: SCREEN,
    type: ELIGIBILITY,
    title: () => 'Let’s check your eligibility for local rebates',
    autoAnswer: [CATEGORIES.LOCAL],
    categories: [CATEGORIES.LOCAL],
    trackingSubaction: constant(TrackingConstant.DISPLAY_LOCAL_REBATES_INTRO),
  },
  [CHECKING_LOCAL_ELIGIBILITY_RESULT_SCREEN_ID]: {
    id: CHECKING_LOCAL_ELIGIBILITY_RESULT_SCREEN_ID,
    variant: SCREEN,
    type: CHECKING_ELIGIBILITY,
    title: () => '',
    categories: [CATEGORIES.LOCAL],
    autoCloseAfter: 1,
  },
  [RESULT_LOCAL_REBATES_SCREEN_ID]: {
    id: RESULT_LOCAL_REBATES_SCREEN_ID,
    variant: SCREEN,
    type: ELIGIBILITY,
    title: (context, stepMeta, isEligible) =>
      isEligible
        ? 'Great! You’re eligible for some local savings.'
        : 'Unfortunately, you do not qualify for any local rebates.',

    categories: [CATEGORIES.LOCAL],
    hideBullets: true,
    trackingSubaction: constant(TrackingConstant.DISPLAY_LOCAL_REBATES_RESULTS),
  },
  [RESULTS_SCREEN_ID]: {
    id: RESULTS_SCREEN_ID,
    variant: SCREEN,
    type: RESULT,
    title: () => 'Nice! We found [count] rebates that you’re eligible for.',
    hideHeadSection: true,
  },

  [TRANSACTION_TYPE_QUESTION_ID]: {
    id: TRANSACTION_TYPE_QUESTION_ID,
    variant: QUESTION,
    type: RADIO_LIST,
    title: ({ make, model }) => `How do you plan to buy your ${make} ${model}?`,
    categories: [CATEGORIES.FEDERAL, CATEGORIES.LOCAL],
    answerOptions: [
      { id: 'FINANCE', title: 'Finance' },
      { id: 'LEASE', title: 'Lease' },
      { id: 'CASH', title: 'Cash' },
    ],
    trackingSubaction: constant(TrackingConstant.TRANSACTION_TYPE),
  },
  [LOWER_INCOME_YEAR_QUESTION_ID]: {
    id: LOWER_INCOME_YEAR_QUESTION_ID,
    variant: QUESTION,
    type: RADIO_LIST,
    title: () =>
      `Will you have a lower modified adjusted gross income in ${getCurrentYear()} or ${getCurrentYear() - 1}?`,
    answerOptions: [
      {
        id: getCurrentYear(),
        title: getCurrentYear(),
      },
      {
        id: (getCurrentYear() - 1).toString(),
        title: (getCurrentYear() - 1).toString(),
      },
    ],
    categories: [CATEGORIES.FEDERAL],
    trackingSubaction: constant(TrackingConstant.TAX_YEAR),
    hint: { text: () => SCREEN_HINT },
  },
  [ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID]: {
    id: ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID,
    variant: QUESTION,
    type: INPUT,
    title: (_, { lowerIncomeYear = getCurrentYear() }) =>
      `What ${
        lowerIncomeYear === getCurrentYear() ? 'is your expected income for' : 'was your income in'
      } ${lowerIncomeYear}?`,
    inputAriaLabel: 'Gross income amount',
    customConfig: { component: StartInputAdornment },
    categories: [CATEGORIES.FEDERAL],
    trackingSubaction: ({ lowerIncomeYear = getCurrentYear() }) =>
      lowerIncomeYear === getCurrentYear()
        ? TrackingConstant.INCOME_CURRENT_YEAR
        : TrackingConstant.INCOME_PREVIOUS_YEAR,
  },
  [ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID]: {
    id: ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID,
    variant: QUESTION,
    type: INPUT,
    title: () => `What was your income in ${getCurrentYear() - 1}?`,
    inputAriaLabel: 'Gross income amount',
    customConfig: { component: StartInputAdornment },
    categories: [CATEGORIES.LOCAL],
    trackingSubaction: constant(TrackingConstant.INCOME_PREVIOUS_YEAR),
    hint: {
      text: state => `${LOCAL_GROSS_INCOME_HINT} ${state}.`,
      expandedSummary: 'Why is this needed?',
      collapsedSummary: 'Why is this needed?',
    },
  },
  [TAX_FILING_STATUS_FEDERAL_QUESTION_ID]: {
    id: TAX_FILING_STATUS_FEDERAL_QUESTION_ID,
    variant: QUESTION,
    type: RADIO_LIST,
    title: (_, { lowerIncomeYear = getCurrentYear() }) =>
      `What ${lowerIncomeYear === getCurrentYear() ? 'will be' : 'was'} your tax filing status in ${lowerIncomeYear}?`,
    categories: [CATEGORIES.FEDERAL, CATEGORIES.LOCAL],
    answerOptions: (incentives, activeCategory, { taxFilingStatuses }) =>
      (taxFilingStatuses || getTaxFilingStatuses(incentives, activeCategory)).map(taxFilingStatus => ({
        title: taxFilingStatus,
      })),
    trackingSubaction: ({ lowerIncomeYear = getCurrentYear() }) =>
      lowerIncomeYear === getCurrentYear()
        ? TrackingConstant.TAX_STATUS_CURRENT_YEAR
        : TrackingConstant.TAX_STATUS_PREVIOUS_YEAR,
  },
  [TAX_FILING_STATUS_LOCAL_QUESTION_ID]: {
    id: TAX_FILING_STATUS_LOCAL_QUESTION_ID,
    variant: QUESTION,
    type: RADIO_LIST,
    title: () => `What was your tax filing status in ${getCurrentYear() - 1}?`,
    categories: [CATEGORIES.LOCAL],
    answerOptions: (incentives, activeCategory, { taxFilingStatuses }) =>
      (taxFilingStatuses || getTaxFilingStatuses(incentives, activeCategory)).map(taxFilingStatus => ({
        title: taxFilingStatus,
      })),
    trackingSubaction: constant(TrackingConstant.TAX_STATUS_PREVIOUS_YEAR),
  },
  [INSTALL_EV_CHARGER_QUESTION_ID]: {
    id: INSTALL_EV_CHARGER_QUESTION_ID,
    variant: QUESTION,
    type: BINARY,
    title: () => 'Do you plan to install a Level 2 EV charger at your home?',
    categories: [CATEGORIES.LOCAL],
    trackingSubaction: constant(TrackingConstant.LEVEL2_CHARGER),
  },
  [ZIP_CODE_QUESTION_ID]: {
    id: ZIP_CODE_QUESTION_ID,
    variant: QUESTION,
    type: ZIP,
    title: ({ make, model }) => `In which zip code will you be registering your ${make} ${model}?`,
    categories: [CATEGORIES.LOCAL],
    trackingSubaction: constant(TrackingConstant.ZIP_CODE),
  },
  [CUSTOMER_OF_QUESTION_ID]: {
    id: CUSTOMER_OF_QUESTION_ID,
    variant: QUESTION,
    type: RADIO_LIST,
    title: () => 'Select your utility provider:',
    categories: [CATEGORIES.LOCAL],
    answerOptions: incentives => [
      ...getUtilityEvChargingOfferRebates(incentives)
        .filter(rebate => get(rebate, 'grantor', '') !== '')
        .map(({ grantor, id }) => ({
          title: id,
          text: grantor,
          hideValue: true,
        })),
      { title: DEFAULT_CUSTOMER_OF_ANSWER, text: 'Not listed (or I don’t have one)', hideValue: true },
    ],
    trackingSubaction: constant(TrackingConstant.CHARGER_INSTALLATION_GRANTOR),
    trackingValueField: 'text',
  },
  [HOUSEHOLD_SIZE_QUESTION_ID]: {
    id: HOUSEHOLD_SIZE_QUESTION_ID,
    variant: QUESTION,
    type: INPUT,
    title: () => 'How many people, including yourself, live in your household?',
    inputAriaLabel: 'Household size',
    categories: [CATEGORIES.LOCAL],
    trackingSubaction: constant(TrackingConstant.HOUSEHOLD_SIZE),
  },
  [PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID]: {
    id: PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID,
    variant: QUESTION,
    type: BINARY,
    title: () => 'Do you participate in any of these income-qualifying programs?',
    categories: [CATEGORIES.LOCAL],
    customConfig: {
      componentName: 'QualifierDescription',
      component: QualifierDescription,
    },
    trackingSubaction: constant(TrackingConstant.ASSISTANCE_PARTICIPANT),
  },
  [RESIDE_DISADVANTAGE_COMMUNITY_QUESTION_ID]: {
    id: RESIDE_DISADVANTAGE_COMMUNITY_QUESTION_ID,
    variant: QUESTION,
    type: BINARY,
    title: () => 'Do you currently reside in a disadvantaged community census tract?',
    categories: [CATEGORIES.LOCAL],
    trackingSubaction: constant(TrackingConstant.ASSISTANCE_PARTICIPANT),
    hint: {
      text: () => DAC_QUESTION_HINT,
    },
  },
  [ENGINE_TYPE_QUESTION_ID]: {
    id: ENGINE_TYPE_QUESTION_ID,
    variant: QUESTION,
    type: RADIO_LIST,
    title: ({ make, model }) => `Which ${make} ${model} engine type are you interested in buying?`,
    categories: [CATEGORIES.FEDERAL, CATEGORIES.LOCAL],
    trackingSubaction: constant(TrackingConstant.ENGINE_TYPE),
    answerOptions: (incentives, activeCategory, stepMeta, context) =>
      context.engineTypes?.map(engineType => ({
        title: engineType,
        text: capitalize(engineType),
        hideValue: true,
      })) ?? [],
  },
};

// Check initialStepIndex if number of start screens changes
export const START_SCREENS = [START_SCREEN_ID];

export const OEM_REBATE_PROGRAM_QUESTIONS_STATIC = [
  CHECK_LOCAL_REBATES_SCREEN_ID,
  ZIP_CODE_QUESTION_ID,
  RESULT_LOCAL_REBATES_SCREEN_ID,
];

export const EV_REBATE_PROGRAM_QUESTIONS_STATIC = [
  CHECK_FEDERAL_REBATES_SCREEN_ID,
  ZIP_CODE_QUESTION_ID,
  ENGINE_TYPE_QUESTION_ID,
  TRANSACTION_TYPE_QUESTION_ID,
  RESULT_FEDERAL_REBATES_SCREEN_ID,
];
export const TAX_CREDIT_PROGRAM_QUESTIONS_STATIC = [
  CHECK_LOCAL_REBATES_SCREEN_ID,
  ZIP_CODE_QUESTION_ID,
  ENGINE_TYPE_QUESTION_ID,
  TRANSACTION_TYPE_QUESTION_ID,
  RESULT_LOCAL_REBATES_SCREEN_ID,
];

export const CHARGER_INSTALLATION_PROGRAM_QUESTIONS_STATIC = [
  CHECK_LOCAL_REBATES_SCREEN_ID,
  ZIP_CODE_QUESTION_ID,
  ENGINE_TYPE_QUESTION_ID,
  TRANSACTION_TYPE_QUESTION_ID,
  INSTALL_EV_CHARGER_QUESTION_ID,
  CUSTOMER_OF_QUESTION_ID,
  RESULT_LOCAL_REBATES_SCREEN_ID,
];

export const STATIC_PROGRAM_QUESTIONS = {
  [PROGRAM_TYPES.EV_REBATE]: EV_REBATE_PROGRAM_QUESTIONS_STATIC,
  [PROGRAM_TYPES.TAX_CREDIT]: TAX_CREDIT_PROGRAM_QUESTIONS_STATIC,
  [PROGRAM_TYPES.CHARGER_INSTALLATION]: CHARGER_INSTALLATION_PROGRAM_QUESTIONS_STATIC,
  [PROGRAM_TYPES.OEM]: OEM_REBATE_PROGRAM_QUESTIONS_STATIC,
};

export const GOLD_CATEGORY_QUESTIONS_MAP = {
  [CATEGORIES.FEDERAL]: [
    CHECK_FEDERAL_REBATES_SCREEN_ID,
    ZIP_CODE_QUESTION_ID,
    ENGINE_TYPE_QUESTION_ID,
    TRANSACTION_TYPE_QUESTION_ID,
    LOWER_INCOME_YEAR_QUESTION_ID,
    ADJUSTED_GROSS_INCOME_FEDERAL_QUESTION_ID,
    TAX_FILING_STATUS_FEDERAL_QUESTION_ID,
    CHECKING_FEDERAL_ELIGIBILITY_RESULT_SCREEN_ID,
    RESULT_FEDERAL_REBATES_SCREEN_ID,
  ],
  [CATEGORIES.LOCAL]: [
    CHECK_LOCAL_REBATES_SCREEN_ID,
    ZIP_CODE_QUESTION_ID,
    ENGINE_TYPE_QUESTION_ID,
    TRANSACTION_TYPE_QUESTION_ID,
    ADJUSTED_GROSS_INCOME_LOCAL_QUESTION_ID,
    TAX_FILING_STATUS_LOCAL_QUESTION_ID,
    HOUSEHOLD_SIZE_QUESTION_ID,
    PARTICIPATE_IN_QUALITY_PROGRAMS_QUESTION_ID,
    RESIDE_DISADVANTAGE_COMMUNITY_QUESTION_ID,
    INSTALL_EV_CHARGER_QUESTION_ID,
    CUSTOMER_OF_QUESTION_ID,
    CHECKING_LOCAL_ELIGIBILITY_RESULT_SCREEN_ID,
    RESULT_LOCAL_REBATES_SCREEN_ID,
  ],
};

export const CHECKING_ELIGIBILITY_PROGRAM_QUESTIONS_MAP = {
  [PROGRAM_TYPES.EV_REBATE]: [CHECKING_FEDERAL_ELIGIBILITY_RESULT_SCREEN_ID],
  [PROGRAM_TYPES.TAX_CREDIT]: [CHECKING_LOCAL_ELIGIBILITY_RESULT_SCREEN_ID],
};
